/*
 * Modfiy element countent counting to specific numbers.
 * eg. count from 0 to 100 or from 0.1 to 1
 *
 *   <span class="total" data-wcount-to="<%= @project.money_pledged %>">0</span>
 *   <script> $('.total').wCountUp(); </script>
 *
 * options:
 *
 *   countTo:
 *     counting up from 0 to target numbers.
 *
 *   decimal (optional):
 *     calculate and formats display in decimal numbers.
 *     default: 0
 *
 *   timeLen (optional):
 *     how many sec to count
 *     default: 4
 *
 *   stepLen (optional):
 *     step pause length in ms
 *     default: 100
 *
 *   localeStr (optional):
 *     display numbers as 1,000
 *     default: false
 *
 */

(function($) {
  $.fn.wCountUp = function(options) {
    // https://gist.github.com/Lobstrosity/1654123
    function commafy(num) {
      return String(num).replace(
        /^(-?\d+)(\d{3}(\.\d+)?)$/,
        function (_, a, b) { return commafy(a) + ',' + b; }
      );
    };

    return this.each(function(){
      var $ele = $(this);

      var set = $.extend({
        countTo: $ele.data('wcount-to') * 1 || 0,
        decimal: $ele.data('wcount-decimal') || 0,
        stepLen: $ele.data('wcount-steplen') || 100,
        timeLen: $ele.data('wcount-timelen') || 4,  // sec
        localeStr: $ele.data('wcount-localestr') || false
      }, options || {});

      if (!set.countTo) return;

      var countTo = set.countTo.toFixed(set.decimal) * 1;
      var diff = countTo / (set.timeLen * 1000 / set.stepLen);
      var current = $ele.text() * 1;

      var aniStart = 0;
      var timeID = null;

      function step(timestamp) {
        if (timestamp - aniStart < set.stepLen){
          timeID = requestAnimationFrame(step);
          return;
        };

        current += diff;
        if (current >= countTo) {
          cancelAnimationFrame(timeID);
          if (set.localeStr) countTo = commafy(countTo);
          $ele.text(countTo);
          return;
        };

        if (set.localeStr)
          $ele.text(commafy(current.toFixed(set.decimal)));
        else
          $ele.text(current.toFixed(set.decimal));

        current *= 1;

        aniStart = timestamp;
        timeID = requestAnimationFrame(step);
      }
      timeID = requestAnimationFrame(step);
    });
  };
})(jQuery);
