/*
 * wFavShare
 *
 * .get()
 *   is used to get count of facebook sharing and favorite
 *
 * usage:
 *
 * * 1 para: an array of object which include article's id
 * * example: [ { id: 2135 }, { id: 1101 }, { id: 218 } ]
 *
 * * 2 para: the parant of target, it might be .article-block
 *
 *
 * */

var wFavShare = {
  get: function(articlesDat, $targetDom){

    if (!window.wFavorite) return;

    var idArray = [];
    articlesDat.forEach(function(ele){
      idArray.push(ele.id);
    });

    $.when(
      wFavorite.get({id: idArray}),
      $.get(womany.api + '/articles/sharing_count', {'ids': idArray.toString()})
    ).done(function(fav, fb_dat){

      if(fb_dat[1] !== 'success' || fb_dat[0].status !== 'success') return;

      var fb = {};
      fb_dat[0].data.articles.forEach(function(ele){
        var isDecimal = ele.sharing_count >= 100; // 1100 -> 1.1k ; 1010 -> 1k
        if(ele.sharing_count > 1000)
          fb[ele.id] = (ele.sharing_count/1000).toFixed(isDecimal) + 'k';
        else
          fb[ele.id] = ele.sharing_count;
      });

      $targetDom.filter('a').each(function(index){

        var $article = $(this);
        var id = idArray[index];

        if(!fb[id] && !fav[id]) return;

        var $fav = $('<span class="favorite">' +
                      (fb[id]  ? ('<i class="fa fa-thumbs-up"></i>' + fb[id]) : '' ) +
                      (fav[id] ? ('<span><i class="fa fa-heart"></i>' + fav[id] + '</span>') : '' ) +
                    '</span>');


        // FIXME: 不能兩個一起 highlight, 上面兩個要另外包
        if (fav.user_favorites && fav.user_favorites.indexOf(id*1) > -1)
          $fav.children('span').addClass('active');

        $article.children('article').append($fav);
      });
    });
  }
};

export default wFavShare;
