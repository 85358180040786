import '../vendors/jquery.tooltipster.min';
import '../vendors/jquery.colorbox';
import '../vendors/slick';
import '../shared/wWaypoint';

import wFavShare   from '../shared/wFavShare';
import wShareTrack from '../shared/wShareTrack';
import wDialog     from '../shared/wDialog';
import wFb         from '../shared/wFb';
import urlObject   from '../vendors/urlobject';

import '../plugin/jquery.wDfp';
import '../plugin/jquery.wCountUp';

var wComponent = {
  page_title: null,

  paddingTop: function(){
    // check if #w-header exist for events/codeforgender
    var pt = $('#w-header').length ? $('#w-header').outerHeight() : 0;
    var $topMenu = $('.component.menu, .component.navbar, .component.nested_navbar');
    if ($topMenu.data('fixedpos')) pt += $topMenu.outerHeight();
    return pt;
  }(),

  init: function(){
    this.page_title = wUtil.pageCategory() + ' - ' + $('title').text().replace(/｜女人迷 Womany/g, '');

    // FIXME: only apply function when component available
    this.initMenu();
    this.initMobileBtnMenu();
    this.initAddCartMobileBtn();
    this.initFeatureScroll();
    this.initQAswitch();
    this.initGallery();
    this.initTimeline();
    this.initArticle();
    this.initArticlesAlt();
    this.initSubscribe();
    this.initShareFB();
    this.initSocialShare();
    this.initYoutube();
    this.initCarousel();
    this.initSlide();
    this.initQaFold();
    this.initProducts();
    this.initProductDetail();
    this.initProduct();
    this.initTeamzine();
    this.initArticleEmbed();
    this.initEditorRef();
    this.initSaleTickets();
    this.initImagesLightbox();
    this.initSlickCarousel();
    this.initCampaignButton();
    this.initDataIntro();

    this.initMobileLink();  // Change to mobile links when on mobile
    this.initComponentLinkTrack();
    this.initDfp();
    this.initComponentInviewTrack();

    if (urlObject().parameters.development) {
      this.initDevMode();
    }
  },
  initDevMode: function() {
    var me = this;
    $('body').addClass('b-previews'); // show preview tag
    var $compo = $('.component');
    if (!$compo.length) return;
    $compo.each(function(index, item) {
      var $item = $(item);
      var compId = $item.attr('data-id');
      var $blockId = `<div style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; background-color: rgba(0, 0, 0, 0.5); color: #FFF; border: 3px solid red; font-size: 20px; padding: 10px 0 0 10px; text-align: left;"> blockId ${compId}</div>`;
      $item.css('position', 'relative').append($blockId);
    })
  },
  initMobileLink: function(){
    if ($(window).width() >= 768) return;

    $('#w-main-content').find('a[data-mobile-href]').each(function(){
      var $this = $(this);
      var mobileLink = $this.attr('data-mobile-href');
      if (mobileLink.length) $this.attr('href', mobileLink);
    });
  },
  initComponentInviewTrack: function() {
    $(window).on('load', function() {
      var $compo = $('.component');
      if (!$compo.length) return;
      $compo.each(function(index, item) {
        var compId = item.getAttribute('id');
        var inview = new wWaypoint({
          element: item,
          enter: function(direction) {
            $(item).addClass('wp-enter');
          },
          entered: function(direction) {
            if (window.ga) { ga('send', 'event', 'website-tracking', 'entry-component-' + compId, 'inview', { nonInteraction: true }) };
            this.destroy();
          }
        });
      })
    })
  },

  initComponentLinkTrack: function(){
    $('.component').on('click', '.component-link', function(e){
      var $panel = $(e.delegateTarget);
      var compoName = $panel.attr('class').replace(' ', '-') + '-' + $panel.data('id');
      if (window.ga) ga('send', 'event', 'website-tracking', compoName, 'openlink_' + this.href);
    });

    $('.component').on('click', 'a[data-trackThis]', function(e){
      var $panel = $(e.delegateTarget);
      var compoName = $panel.attr('class').replace(' ', '-') + '-' + $panel.data('id');
      var $this = $(this);
      var trackValue = $this.data('trackvalue') || null;
      if (!window.ga) return;

      if (trackValue)
        ga('send', 'event', 'website-tracking', compoName, 'openlink_' + $this.attr('data-trackThis') + '_' + $this.data('id'), trackValue);
      else
        ga('send', 'event', 'website-tracking', compoName, 'openlink_' + $this.attr('data-trackThis') + '_' + $this.data('id'));
    });
  },

  initDfp: function(){
    $('.component.dfp').each(function(){
      $(this).find('.wdfp').wDfp();
    });
  },

  initSlide: function(){
    var me = this;
    var _ANILENGTH = 600; // transition length
    var _ANIINTERVAL = 3000; // transition interval
    var _EVENTDETECTVAL = 1000;

    $('.component.slide').each(function(){
      var $panel = $(this);
      var compoName = $panel.attr('class').replace(' ', '-') + '-' + $panel.data('id');
      var component_id = $panel.data('id');

      var $slides = $panel.find('.slides');
      var $indicator = $slides.children('.indicator');
      var $indicators = $indicator.children('li');
      var $indicators$ = $indicators.map(function(){ return $(this); });
      var $items = $slides.children('.items');
      var $cards = $items.children('div');
      var $cards$ = $cards.map(function(){ return $(this); });

      var bnrNum = $items.data('num');
      var currentIndex = 0;

      var bkImgSampleUrl = $cards$[0].find('img').attr('src');

      var getImg = function(src){
        var _dfd = $.Deferred();
        var _img = new Image();
        _img.onload = function(){
          _dfd.resolve(_img);
        };
        _img.onerror = _dfd.reject;
        _img.src = src;
        return _dfd.promise();
      };

      getImg(bkImgSampleUrl).done(function(image){
        var imgAspect = Math.floor(image.height / image.width * 100) / 100;
        var target = '#w-main-content #block' + component_id + ' .slides:before';
        var rule = 'padding-bottom: ' + imgAspect * 100 + '%;';
        $('<style>' + target + ' { '+ rule +' }</style>').appendTo('head');
      });

      var scrollToBanner = function(index){
        if (index == bnrNum)
          index = 0;
        if (index == -1)
          index = bnrNum - 1;

        $cards$[index].addClass('show');
        $indicators.removeClass('active');
        $indicators$[index].addClass('active');
        // fix bottom image covered by top
        if (index < currentIndex) $cards$[currentIndex].removeClass('show');
        window.setTimeout(function(){
          $cards$[currentIndex].removeClass('show');
          currentIndex = index;
        }, 500);
      };

      // Auto scroll
      var slideTimer = window.setInterval(function(){
        if ($slides.hasClass('hover')) return;
        scrollToBanner(currentIndex + 1);
      }, _ANIINTERVAL);

      // Pause scrolling when mouse on
      $slides
        .mouseenter(function(){
          $slides.addClass('hover');

          if ($slides.data('mouseleavetimer'))
            window.clearTimeout($slides.data('mouseleavetimer'))
        })
        .mouseleave(function(){
          var mouseLeaveTimer = window.setTimeout(function(){
            $slides.removeClass('hover');
          }, _EVENTDETECTVAL);
          $slides.data('mouseleavetimer', mouseLeaveTimer);
        });

      // Hover on indicator to switch
      $indicator.on('click, mouseenter', 'li', function(){
        var newIndex = $(this).data('index');
        if (newIndex == currentIndex) return false;
        scrollToBanner($(this).data('index'));
      });
    });
  },

  initCarousel: function(){
    var me = this;
    var _ANILENGTH = 600; // transition length
    var _ANIINTERVAL = 3000; // transition interval
    var _EVENTDETECTVAL = 1000;

    $('.component.carousel').each(function(){
      var $panel = $(this);
      var compoName = $panel.attr('class').replace(' ', '-') + '-' + $panel.data('id');
      var component_id = $panel.data('id');
      var $slides = $panel.find('.slides');

      var $items = $slides.children('.items');
      var $cards = $items.children('div');

      var $context = $panel.find('.context');
      var $contextArticles = $context.children('article');

      var bnrNum = $items.data('num');
      var imgAspect = null;
      var bannerPos = [];
      var currentIndex = 0;

      $items.css({ 'width': bnrNum * 100 + '%' });

      var bkImgSampleUrl = /(http.*)\)/.exec($items.children('div:first').css('background-image'))[1].replace('"','');

      var getImg = function(src){
        var _dfd = $.Deferred();
        var _img = new Image();
        _img.onload = _dfd.resolve(_img);
        _img.onerror = _dfd.reject;
        _img.src = src;
        return _dfd.promise();
      };

      var onResize = function(imgAspect){
        var bnWidth = $slides.width();
        var bnHeight = bnWidth * imgAspect;
        $cards.css({'width': bnWidth, 'height': bnHeight});
        $items.css({'width': $items.data('num') * bnWidth});

        bannerPos = [];
        for (var i=0; i<bnrNum; i++){ bannerPos.push(bnWidth * i); };

        // Show correspondent article on mobile
        // Adjust article container's dimension
        if ($context.is(':visible')){
          $context.attr('data-heightSet', false);
          $context.css('height', function(){
            var heigest = 0;
            $contextArticles.each(function(){
              var $this = $(this);
              var curHeight = $this.outerHeight();
              $this.css({'height': curHeight, 'width': bnWidth * 0.94});
              if (curHeight > heigest) heigest = curHeight;
            });
            return heigest;
          });
          $context.attr('data-heightSet', true);
        };
      };

      getImg(bkImgSampleUrl).done(function(image){
        imgAspect = Math.floor(image.height / image.width * 100) / 100;
        $slides.css('padding-bottom', imgAspect * 100 + '%');
        onResize(imgAspect);
        $(window).resize(function() { onResize(imgAspect); });
      });

      var noCheckIndex = false;
      var onSlideScrollEnd = function(){
        currentIndex = function(curLeft){
          if (curLeft == 0) return 0;
          for (var i=0; i<bannerPos.length; i++){
            if (Math.abs((bannerPos[i]/curLeft) - 1) < 0.1) return i;
          };
        }($slides.scrollLeft());

        if (noCheckIndex){
          window.setTimeout(function(){ noCheckIndex = false; }, _EVENTDETECTVAL);
          return;
        };

        // Move to first/last when reach last/first
        if (currentIndex == bnrNum - 1){
          $slides.scrollLeft(0);
          currentIndex = 0;
          noCheckIndex = true;
        }
        else if (currentIndex == 0){
          currentIndex = bnrNum - 1;
          $slides.scrollLeft(bannerPos[currentIndex]);
          noCheckIndex = true;
        };

        // Show correspondent article on mobile
        if ($context.is(':visible')){
          var index = currentIndex;
          if (index == bnrNum - 1) index = 0;
          $contextArticles.removeClass('show');
          $contextArticles.filter(':eq(' + index + ')').addClass('show');
        };
      };
      var scrollingTimer = null;
      $slides.on('scroll', function(){
        if (scrollingTimer) window.clearTimeout(scrollingTimer);
        scrollingTimer =  window.setTimeout(onSlideScrollEnd, _EVENTDETECTVAL);
      });

      var scrollToBanner = function(index){
        var pos = bannerPos[index];
        $slides.animate({ scrollLeft: pos }, _ANILENGTH, 'swing');
      };
      var scrollBannerToRight = function(){
        currentIndex += 1;
        scrollToBanner(currentIndex);
      };
      var scrollBannerToLeft = function(){
        currentIndex -= 1;
        if (currentIndex == -1) currentIndex = bnrNum - 2;
        scrollToBanner(currentIndex);
      };

      // Auto scroll
      var slideTimer = window.setInterval(function(){
        if ($slides.hasClass('hover')) return;
        // impressions[currentIndex]++;
        scrollBannerToRight();
      }, _ANIINTERVAL);

      // Pause scrolling when mouse on
      // $slides.add($controls).add($indicators)
      $slides
        .mouseenter(function(){
          $slides.addClass('hover');

          if ($slides.data('mouseleavetimer'))
            window.clearTimeout($slides.data('mouseleavetimer'))
        })
        .mouseleave(function(){
          var mouseLeaveTimer = window.setTimeout(function(){
            $slides.removeClass('hover');
          }, _EVENTDETECTVAL);
          $slides.data('mouseleavetimer', mouseLeaveTimer);
        });
    });
  },

  initYoutube: function(){
    var $youTube = $('.component.youtube');
    if (!$youTube.length) return;

    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = function() {
      $youTube.each(function(){
        var $this = $(this);
        var $yt = $this.find('.youtube');
        var serialID = $this.data('id');
        $yt.attr('id', 'component-youtube-' + serialID);

        initYoutubePlayer({
          componentID: serialID,
          videoID: $yt.data('videoid'),
          isPlaylist: $yt.data('playlist'),
          autoplay: $yt.data('autoplay'),
          loop: $yt.data('loop')
        });
      });
    };

    function initYoutubePlayer(opt){
      var playerOpts = {
        playerVars: {
          rel: 0,
          showinfo: 0
        },
        events: {
          'onReady': onVideoReady,
          'onStateChange': onVideoStatusChange
        }
      };

      if (!opt.isPlaylist){
        playerOpts.videoId = opt.videoID;
        if (opt.loop) playerOpts.playerVars.playlist = opt.videoID;
      }
      else {
        playerOpts.playerVars.listType = 'playlist';
        playerOpts.playerVars.list = opt.videoID;
      };

      if (opt.loop) playerOpts.playerVars.loop = 1;

      var player = new YT.Player('component-youtube-' + opt.componentID, playerOpts);
    };

    function onVideoReady(event) {
      var youtube = event.target;
      var ytFrame = youtube.getIframe();

      if (ytFrame.getAttribute('data-autoplay') * 1) { // Autoplay when youtube get insight
        youtube.mute();

        var inview = wWaypoint.Inview({
          element: ytFrame,
          entered: function(direction) { youtube.playVideo(); },
          exit: function(direction) { youtube.pauseVideo(); }
        });
      };
    };

    function onVideoStatusChange(event){
      if (window.ga && event.data == YT.PlayerState.PLAYING){
        ga('send', 'event', 'website-tracking', event.target.getIframe().id, 'playVideo');
        return;
      };
      if (window.ga && event.data == YT.PlayerState.ENDED){
        ga('send', 'event', 'website-tracking', event.target.getIframe().id, 'playVideoEnd');
        return;
      };
    };
  },

  initSocialShare: function(){
    $('.component.socialshare').each(function(){
      var $panel = $(this);
      var strUrl = location.protocol+ '//' + location.host + location.pathname;
      $panel.find('.wshare-sharetools').attr('data-url', strUrl);
      wShareTrack.renderSharetools($panel);
    });
  },

  initMobileBtnMenu: function(){
    var me = this;

    $('.component.mobilebtn').each(function(){
      var $panel = $(this);
      var compoName = $panel.attr('class').replace(' ', '-') + '-' + $panel.data('id');
      var component_id = $panel.data('id');
      var $nav = $panel.find('nav');

      $nav.find('a').click(function(e){
        var hash = e.delegateTarget.hash;
        if (!hash) return;

        me.scrollingTo(hash);

        if (window.ga) ga('send', 'event', 'website-tracking', compoName, 'openlink_' + this.href);
        return false;
      });
    });
  },

  scrollingTo: function(hash){
    var me = this;

    if (hash == 'top'){
      $('html, body').animate({ scrollTop: 0 });
      return;
    };

    if (jQuery.isNumeric(hash)){
      $('html, body').animate({ scrollTop: $(window).scrollTop() + hash });
      return;
    };

    var $target = (hash instanceof jQuery)? hash : $(hash);
    if (!$target.length) return;
    $('html, body').animate({scrollTop: $target.offset().top - me.paddingTop}, 600);
  },

  initMenu: function(){
    var me = this;

    $('.component.menu, .component.navbar, .component.nested_navbar').each(function(){
      var $panel = $(this);
      var component_id = $panel.data('id');
      var $nav = $panel.find('nav');

      $nav.find('.openMenu a').text(window.womany.page_title);

      $nav.on('click', function(e) {
        var $target = $(e.target);

        if ($panel.hasClass('menu') || $panel.hasClass('navbar')) return;

        // for nested navbar
        if ($target.hasClass('nav-open-btn')) {
          e.preventDefault();
          $nav.toggleClass('ml-nav-open');

          if (!$nav.hasClass('ml-nav-open')) {
            $nav.find('.ml-subnav-container').each(function(index, item){
              $(item).removeClass('open');
            });
          }
        };

        // toggle nested navbar subnav
        if ($target.hasClass('has-nav-inner')) {
          e.preventDefault();
          $target.parents('li.item').find('.ml-subnav-container').toggleClass('open');
        }
      })

      $nav.find('a').click(function(e){
        if (!(!($nav.find('.openMenu:visible').length)))
          $nav.toggleClass('menu-open');

        if (!e.delegateTarget.hash) return;

        // check if hash in current page
        var $hash = $(e.delegateTarget.hash);
        if ($hash.length) {
          me.scrollingTo($hash);
          return false;
        }
      });

      var $navLinks = $();
      if ($panel.hasClass('nested_navbar')) {
        $navLinks = $nav.find('.itemlink, .ml-logo');
      } else {
        $navLinks = $nav.find('.menuItem a');
      }

      var $navTargets = $();

      $navLinks.each(function(){
        if ($(this).hasClass('ml-logo')) return;

        var targetHash = $(this).attr('href');
        var currentUrl = location.hostname + location.pathname;
        targetHash = targetHash.replace(/^https?:\/\//, '').replace(/\?.*$/,'').replace(currentUrl, '');

        // e.g. href="#block12345"
        if (targetHash[0] == '#') {
          $(this).attr('href', targetHash);
          $navTargets = $navTargets.add($(targetHash));
          return;
        }
      });

      // Nav point to current page
      $navLinks.each(function(){
        var $nav = $(this);

        var targetUrl = $nav.attr('href').replace(/^https?:\/\//, '').replace(/\?.*$/,'');
        if (targetUrl[targetUrl.length - 1] != '/') targetUrl += '/';
        var currentUrl = location.hostname + location.pathname;
        if (currentUrl[currentUrl.length - 1] != '/') currentUrl += '/';

        if (targetUrl == currentUrl){
          $nav.addClass('current').click(function(){
            me.scrollingTo('top');
            $navLinks.removeClass('current');
            $nav.addClass('current');
            return false;
          });
        };
      });

      if (!$navTargets.length) return;

      // Waypoint for scroll down
      $navTargets.each(function() {
        var target = $(this);
        wWaypoint({
          element: target[0],
          handler: function(direction) {
            if (direction != 'down') return;
            $navLinks.removeClass('current');
            $navLinks.filter('[href="#' + this.element.id + '"]').addClass('current');
          },
          offset: me.paddingTop + 10
        });
      });

      // Waypoint for scroll up
      $navTargets.each(function(){
        var target = $(this);
        wWaypoint({
          element: target[0],
          handler: function(direction) {
            if (direction != 'up') return;
            $navLinks.removeClass('current');
            $navLinks.filter('[href="#' + this.element.id + '"]').addClass('current');
          },
          offset: function(){ return -0.7 * target.outerHeight(); }
        });
      });
    });
  },
  initCampaignButton: function() {
    var me = this;
    $('.component.campaign-button').each(function(){
      var $panel = $(this);
      var $links = $panel.find('a');
      $links.on('click', function(e){
        // check if hash in current page
        var $hash = $(e.delegateTarget.hash);
        if ($hash.length) {
          me.scrollingTo($hash);
          return false;
        }
      });
    })
  },

  initShareFB: function(){
    $('.component.sharefb').each(function(){
      var $panel = $(this);
      var component_id = $panel.data('id');
      var $shareBtn = $panel.find('.fbshare');

      $shareBtn.click(function(){
        // if ($shareBtn.hasClass('done')) return false;
        var clickID = null;
        var shareUrl = location.hostname + location.pathname + '?ref=block-fb';

        var shareData = {
          link: shareUrl,
          recordFunc: function(response){
            // if (!response || !response.post_id) return;
            var rid = (response && response.post_id)? response.post_id : '000000';

            $shareBtn.addClass('done');
            if (window.ga) ga('send', 'event', 'website-tracking', 'component-sharefb-' + component_id, 'share_done');

            function recordClick(){
              if (!clickID) {
                window.setTimeout(recordClick(), 1000);
                return;
              };
              $.post(womany.api + '/actions/click/' + clickID + '/record', {
                'source': 'facebook',
                'data': rid
              }, 'json');
            };
            recordClick();
          }
        };
        wFb.share(shareData);
        if (window.ga) ga('send', 'event', 'website-tracking', 'component-sharefb-' + component_id, 'share_click');

        $.ajax({
          method: 'POST',
          url: womany.api + '/actions/click',
          data: {
            'id': component_id,
            'type': 'CollectionComponent',
            'reason': 'component_facebook_share',
            'url': location.href
          },
          dataType: 'json',
          xhrFields: { withCredentials: true }
        })
        .done(function(dat){
          clickID = dat.id;
        });

        return false;
      });
    });
  },

  initSubscribe: function(){
    $('.component.subscribe').each(function(){
      var $panel = $(this);
      var component_id = $panel.data('id');
      var $subButton = $panel.find('.sub');

      if (!(window.wProfile && wProfile.profile.login)){
        $subButton
          .attr('title', '請先登入才可以訂閱喔')
          .tooltipster({
            delay: 0,
            side: 'bottom'
          })
          .click(function(){
            window.location = '/users/auth/womany';
            return false;
          });
        return;
      };

      $subButton.click(function(){
        if ($subButton.hasClass('done')) return false;

        var $taglist = $panel.find('.taglist');
        var tags = [], interests = [];

        $taglist.find('.tag-tag').each(function(){
          tags.push($(this).data('id'));
        });
        $taglist.find('.tag-interest').each(function(){
          interests.push($(this).data('id'));
        });
        tags = tags.join(',');
        interests = interests.join(',');

        function subscribe(dat) {
          return wFavorite.set(dat);
        }

        var fetchSubscription = {
          interest: interests ? subscribe({id: interests, type: 'Interest'}) : null,
          tag: tags ? subscribe({id: tags, type: 'Topic'}) : null
        };

        $.when(fetchSubscription.interest, fetchSubscription.tag)
        .then(function(){
          $subButton.addClass('done');

          // if (window.wPoint) wPoint.get('subscribe', 'interest_' + me.dat.id); FIXME cannot send duplicate id to wpoint at once
          if (window.ga) ga('send', 'event', 'website-tracking', 'component-subscription-' + component_id, 'subscribe');
        });
      });
    });
  },

  initTimeline: function(){
    $('.component.timeline').each(function(){
      var $panel = $(this);
      var component_id = $panel.data('id');

      $panel.find('.detail-block').on('click', 'a.cooperative', function(){
        if (window.ga) ga('send', 'event', 'website-tracking', 'component-timeline-' + component_id, 'open_cooperative');
      });
    });
  },

  initArticle: function(){
    $('.component.articles').each(function(){
      var $panel = $(this);
      $panel.find('.icon-block').click(function(){
        if (window.ga) ga('send', 'event', 'website-tracking', 'component-articles-' + $panel.data('id'), 'open_icon');
      });

      // "notify when article get published" button
      if (!window.wFavorite) return;

      $panel.find('.favset').each(function(){
        var $favBtn = $(this);
        var id = $favBtn.data('id');
        var favtype = $favBtn.data('type');

        if (!id) return;
        if (!favtype || favtype != 'Article') return; // Currently only support article, check wFavorite

        if (!(window.wProfile && wProfile.profile.login)){
          $favBtn
            .attr('title', '請先登入才能通知你喔')
            .tooltipster({
              delay: 0,
              side: 'bottom'
            })
            .click(function(){
              window.location = '/users/auth/womany';
              return false;
            });
          return;
        };

        wFavorite.get({id: id, type: favtype}).done(function(resp){
          if (resp.user_favorites.indexOf(id) > -1) $favBtn.toggleClass('add');
        });

        $favBtn.click(function(){
          if ($favBtn.hasClass('add')){
            wFavorite.set({id: id, type: favtype}).done(function(){
              $favBtn.toggleClass('add');
            });
            if (window.ga) ga('send', 'event', 'website-tracking', 'component-articles-' + $panel.data('id'), 'set_notice_' + $favBtn.data('blockid'));
          }
          else {
            wFavorite.rm({id: id, type: favtype}).done(function(){
              $favBtn.toggleClass('add');
            });
            if (window.ga) ga('send', 'event', 'website-tracking', 'component-articles-' + $panel.data('id'), 'remove_notice_' + $favBtn.data('blockid'));
          };
          return false;
        });
      });

      // if dfp available
      $(this).find('.wdfp').wDfp();

      // get fav count & sharing count
      var articlesDat = [];
      $panel.find(".article-section").children(".article-block").each(function(i, ele){
        articlesDat.push({ id: ele.getAttribute("data-article-id") });
      });
      wFavShare.get(articlesDat, $panel.find('.article-block'));

      $panel.find('.context h2').each(function(index, item) {
        var $item = $(item);
        var articleTitle = wUtil.truncateString($item.text(), 36);
        $item.text(articleTitle);
      })

    });
  },

  initArticlesAlt: function(){
    $('.component.articlesalt').each(function(){
      var $panel = $(this);
      var componentID = $panel.data('id');
      var compoName = $panel.attr('class').replace(' ', '-') + '-' + componentID;

      // Click on author
      $panel
        .on('click', '.author', function(){
          var author_url = $(this).data('url');
          window.open(author_url);
          if (window.ga) ga('send', 'event', 'website-tracking', compoName, 'openlink_' + author_url);
          return false;
        })
        .on('click', '.js-fbshare', function(){
          var $shareBtn = $(this);
          var $tgtArticle = $($shareBtn.parents('.article-block'));
          var shareUrl = location.hostname + $tgtArticle.attr('href');
          var shareTitle = shareUrl.match(/\/(\d+)$/);
          shareTitle = (shareTitle.length == 2)? shareTitle[1] : 'N/A';
          shareTitle += ('_' + $tgtArticle.attr('title'));

          var shareData = {
            link: shareUrl,
            recordFunc: function(response){
              // if (!response || !response.post_id) return;
              var rid = (response && response.post_id)? response.post_id : '000000';

              $.ajax({
                method: 'POST',
                url: '/components/' + componentID + '/articles/' + $shareBtn.data('component-article-id') + '/sharing',
                data: { 'fb_post_id': rid },
                dataType: 'json',
                xhrFields: { withCredentials: true }
              })
              .done(function(dat){
                if (!dat || dat.status != 'success') return;
                // Update sharing counts
                var html = $shareBtn.html();
                $shareBtn.html(html.replace(/\d+/, $shareBtn.data('count') + 1));
              });
              if (window.ga) ga('send', 'event', 'website-tracking', compoName, 'share_post_' + shareTitle);
            }
          };

          wFb.share(shareData);
          return false;
        });

      $panel.find('.context h2').each(function(index, item) {
        var $item = $(item);
        var articleTitle = wUtil.truncateString($item.text(), 36);
        $item.text(articleTitle);
      })
    });
  },

  initGallery: function(){
    var me = this;

    $('.component.gallery').each(function(){
      var $panel = $(this);
      var component_id = $panel.data('id');
      var compoName = $panel.attr('class').replace(' ', '-') + '-' + component_id;

      var $gallBox = $panel.find('.gallbox');
      var $imgBoxs = $gallBox.children('.gallimg');

      $gallBox.attr('data-shareable', $gallBox.data('shareable') == '1');

      // Youtube or Image
      $imgBoxs.each(function(){
        var $this = $(this);
        if ($this.attr('href').indexOf('youtube') > -1)
          $this.addClass('youtube');
        else
          $this.addClass('image');
      });

      // Lightbox
      var imgboxSetting = {
        rel: function(){ return 'gallery-' + component_id + '-group'; }(),
        closeButton: true,
        scrolling: false,
        maxWidth: '95%',
        maxHeight: '95%',
        className: 'galLightbox',
        close: '',
        current: "{current} / {total}",
        previous: "<i class='fa fa-chevron-circle-left'>",
        next: "<i class='fa fa-chevron-circle-right'>",
        onComplete: function(){
          var index = $.colorbox.element().data('id');
          if (window.ga) ga('send', 'event', 'website-tracking', 'component-gallery-' + component_id, 'lightbox_show_' + index);
        },
        onLoad: function(){
          if (!$gallBox.data('shareable')) return;

          // remove original share button if flip between images
          var $oldShareBtn = $('#cboxContent').find('.sharefb');
          if ($oldShareBtn.length) $imgBoxs.filter("[data-id='" + $oldShareBtn.data('tgtid') + "']").append($oldShareBtn);

          $.colorbox.element().find('.sharefb').appendTo($('#cboxContent'));
        },
        onCleanup: function(){
          if (!$gallBox.data('shareable')) return;
          $('#cboxContent').find('.sharefb').appendTo($.colorbox.element());
        }
      };
      var videoboxSetting = jQuery.extend({
        iframe: true,
        width: $(window).height() * 0.8 / 9 * 16,
        height: $(window).height() * 0.8,
        maxWidth: '95%',
        maxHeight: '80%'
      }, imgboxSetting);

      if ($(window).width() > 440){   // temp select a width to block colorbox that no time to fix layout in colorbox
        $imgBoxs.filter('.youtube').colorbox(videoboxSetting);
        $imgBoxs.filter('.image').colorbox(imgboxSetting);
      }
      else {
        $imgBoxs.click(function(){
          var index = $(this).data('id');
          if (window.ga) ga('send', 'event', 'website-tracking', 'component-gallery-' + component_id, 'openlink_gallery_' + index);
        });
      };

      // Share images button
      if ($gallBox.data('shareable')){
        var shareOpt = window.wGalleryDat[component_id];
        var $shareBtn = $("<i class='sharefb fab fa-facebook-square' title='分享至 Facebook'></i>").appendTo($imgBoxs);

        $imgBoxs.each(function(index){
          var $imgBox = $(this);
          $imgBox.children($shareBtn)
            .attr('data-tgtid', $imgBox.data('id'))
            .tooltipster({ delay: 0, side: 'left', trackOrigin: true });
        });

        $shareBtn.click(function(){
          var imgID = $(this).data('tgtid');
          var $img = $imgBoxs.filter("[data-id='" + imgID + "']");

          var shareData = {
            picture: function(){
              if ($img.hasClass('youtube'))
                return $img.css('background-image').match('(http.*)\"')[1];
              else
                return $img.attr('href');
            }(),
            recordFunc: function(response){
              // if (!response || !response.post_id) return;
              if (window.ga) ga('send', 'event', 'website-tracking', compoName, 'share_img_' + imgID, {'page': window.location.pathname});
            }
          };
          if (shareOpt.title == 'image_title') shareData.name = $img.attr('title');
          if (shareOpt.desc == 'none') shareData.description = ' ';
          if (shareOpt.autoshow * 1)
            shareData.link = $("meta[property='og:url']").attr('content') + '#' + compoName + '-' + imgID;

          wFb.share(shareData, true);
          return false;
        });
      };
    });

    // Default open lightbox
    var urlhash = window.location.hash;
    if (urlhash) {
      var hash = urlhash.match(/#component-gallery-(\d+)-(\d+)$/);
      if (hash && hash.length == 3){
        me.scrollingTo('#block' + hash[1]);
        window.setTimeout(function(){
          $('.component.gallery .gallimg[data-id=' + hash[2] + ']').click();
        }, 1000);
      }
    };
  },

  initFeatureScroll: function(){
    var me = this;
    var $feature = $('.component.feature');

    var $next = $feature.next('.component');
    var hash = '#' + $next.attr('id');

    $feature.find('.next').click(function(){
      me.scrollingTo(hash);
      if (window.ga) ga('send', 'event', 'website-tracking', 'component-feature-' + $feature.data('id'), 'scrollDown');
    });

    $feature.find('a.cooperative').click(function(){
      if (window.ga) ga('send', 'event', 'website-tracking', 'component-feature-' + $feature.data('id'), 'open_cooperative');
    });
  },

  _bindSwipeEvent: function($feature, moveTo){
    var gesturePos = {
      start: {},
      end: {}
    };

    var handleGesure = function() {
      if (gesturePos.end.x < gesturePos.start.x) {
        moveTo('next');
      }
      if (gesturePos.end.x > gesturePos.start.x) {
        moveTo('last');
      }
    }

    // for swipe
    $feature.find('.feature')[0].addEventListener('touchstart', function(event){
      gesturePos.start.x = event.changedTouches[0].screenX;
    }, false);
    $feature.find('.feature')[0].addEventListener('touchend', function(event){
      gesturePos.end.x = event.changedTouches[0].screenX;
      handleGesure();
    }, false);
  },

  initQaFold: function(){
    $('.component.qa-fold').each(function(index, item){
      let $item = $(item);
      let theme = $item.attr('data-theme');
      let defaultFold = $item.attr('data-default-style') == 'fold' ? true : false;
      let singleLayer = theme == 'single_layer' ? true : false;
      $(this)
        .on('click', '.qa-section-title', function(){
          if (singleLayer) return;
          var $secTitle = $(this);
          var $next = $secTitle.next();
          $secTitle.toggleClass('show');

          while ($next.prop("tagName") == 'SECTION') {
            $next.slideToggle();
            $next = $next.next();
          }
        })
        .on('click', 'h4.q-block', function(){
          $(this).toggleClass('show').next().slideToggle();
        });
      if (!defaultFold) return;
        $item.find('.qa-section-title, h4.q-block').each(function(index, qblock){
          $(qblock).trigger('click');
        })
    });
  },

  _handleProductCta: function($cta_dom){
    var me = this;
    var $product = $cta_dom.parents('.component');

    $cta_dom
      .on('click', '.cta-btn.buy-it', function(event){
        event.preventDefault();

        if (window.ga) ga('send', 'event', 'website-tracking-ec', 'click buy-it-btn', me.page_title);

        var id = $(this).attr('data-pi-id');
        if (piidToNewShopURL[id] !== undefined) {
          window.location.href = piidToNewShopURL[id];
          return false;
        }

        var quantity = $product.find(`.product-quantity .controller[data-pi-id="${id}"] input[type="number"]`).val() || 1;
        wUtil.linkFormSubmit(womany.domain_url + '/orders?instance_id=' + id + "&quantity=" + quantity);

        return false;
      })
      .on('click', '.cta-btn.add-cart', function(event){
        event.preventDefault();

        var id = $(this).attr('data-pi-id');
        if (piidToNewShopURL[id] !== undefined) {
          window.location.href = piidToNewShopURL[id];
          return false;
        }

        var quantity = $product.find(`.product-quantity .controller[data-pi-id="${id}"] input[type="number"]`).val() || 1;

        if (window.ga) {
          ga('send', 'event', 'website-tracking-ec', 'click add-cart-btn', me.page_title);
        }

        if (!$('.mini-cart-container').hasClass('hide')) $('#mini-cart-trigger').click();

        $.ajax({
          method: 'POST',
          url: womany.api + '/e_commerce/v1/cart/items?instance_id=' + id + "&quantity=" + quantity,
          xhrFields: { withCredentials: true }
        })
        .done(function(res) {

          if ($('#mini-cart-trigger').length != 0) {
            if (window.ga) ga('send', 'event', 'website-tracking-ec', 'open-03-0-by-add-cart-btn', me.page_title);
            $('#mini-cart-trigger').click();
            return;
          }

          wDialog.alert('成功加入購物車<br>目前購物車有 ' + res.items_count + ' 項商品', '', '立即去結帳').done(function(){
            wUtil.linkFormSubmit(womany.domain_url + '/orders'); // go to /orders
          });

        })
        .fail(function(e) {

          if (e.status == 404) {
            wDialog.alert('找不到此商品。');
          }
          else if (e.status == 406) {
            wDialog.alert('商品庫存不足。');
          }
          else if (e.responseJSON.message == "找不到購物車 (Cart not found)") {
            wDialog.alert('出問題了，請重試。');
          }

          if (window.console && e.status != 404) console.error(e);
        });

        return false;
      });
  },

  _handleProductGuest: function($product){

    $product.on('click', '.cta-btn.add-cart, .cta-btn.buy-it, .product-popularity', function(e){
      wDialog.confirm('還不是 womany 會員嗎？', '', '馬上登入購物去').done(function(res){
        if (res) window.location = 'https://member.womany.net/oauth/authorize?response_type=code&scope=shopline&client_id=33f680a58e4552f5dd8c3dd48d98d0bb1f51dc2920990cbe78b6316d7891fcce&redirect_uri=https%3A%2F%2Fshop.womany.net%2Foauth%2Fcustom%2F6050398ea3546c0032a7ed32%2Fcallback';
      });

      return false;
    });
  },

  initAddCartMobileBtn: function(){
    var me = this;
    var $add_cart_btn = $('.component.add-cart-mobilebtn');

    if ($add_cart_btn.length == 0) return false;

    if (!(window.wProfile && wProfile.profile.login)) {
      $('.fixed-cta-btn').click(function(){
        wDialog.confirm('還不是 womany 會員嗎？', '', '馬上登入購物去').done(function(res){
          if (res) window.location = 'https://member.womany.net/oauth/authorize?response_type=code&scope=shopline&client_id=33f680a58e4552f5dd8c3dd48d98d0bb1f51dc2920990cbe78b6316d7891fcce&redirect_uri=https%3A%2F%2Fshop.womany.net%2Foauth%2Fcustom%2F6050398ea3546c0032a7ed32%2Fcallback';
        });
      });
    }
    else {
      var $fixed_btn = $add_cart_btn.find('.fixed-cta-btn');
      $fixed_btn.on('click', function(){
        var product_instance_id = $fixed_btn.attr('data-product-instance-id');
        if (piidToNewShopURL[product_instance_id] !== undefined) {
          window.location.href = piidToNewShopURL[product_instance_id];
          return false;
        }

        if (!product_instance_id) {
          wDialog.alert('商品庫存不足。');
          $fixed_btn.addClass('out-of-stock');
          $fixed_btn.html('已完售');
        }

        if ($fixed_btn.hasClass('out-of-stock')) {
          if (window.ga) ga('send', 'event', 'website-tracking-ec', 'click fixed-add-cart-btn (out-of-stock)', me.page_title);
          return;
        }
        if ($fixed_btn.hasClass('error')) {
          if (window.ga) ga('send', 'event', 'website-tracking-ec', 'click fixed-add-cart-btn (error)', me.page_title);
          return wDialog.alert();
        }

        if (window.ga) ga('send', 'event', 'website-tracking-ec', 'click fixed-add-cart-btn', me.page_title);

        if (!$('.mini-cart-container').hasClass('hide')) $('#mini-cart-trigger').click();

        $.ajax({
          method: 'POST',
          url: womany.api + '/e_commerce/v1/cart/items?instance_id=' + product_instance_id + "&quantity=1",
          xhrFields: { withCredentials: true }
        })
        .done(function(res) {
          if ($('#mini-cart-trigger').length != 0) {
            if (window.ga) ga('send', 'event', 'website-tracking-ec', 'open-03-0-by-add-cart-btn (fixed)', me.page_title);
            $('#mini-cart-trigger').click();
            return;
          }

          wDialog.alert('成功加入購物車<br>目前購物車有 ' + res.items_count + ' 項商品', '', '立即去結帳').done(function(){
            wUtil.linkFormSubmit(womany.domain_url + '/orders'); // go to /orders
          });

        })
        .fail(function(e, m) {
          if (e.status == 404 || e.status == 406) {
            wDialog.alert('商品庫存不足。');
            $fixed_btn.addClass('out-of-stock');
            $fixed_btn.html('已完售');
          }
          else if (e.responseJSON.message == "找不到購物車 (Cart not found)") {
            wDialog.alert('出問題了，請重試。');
            $fixed_btn.addClass('error');
            $fixed_btn.html('出問題了，請聯絡客服');
          }

          if (window.console && e.status != 404) console.error(e);
        });

        return false;
      });
    }
  },

  initProducts: function(){
    var me = this;
    $('.component.four-products, .component.highlight-products').each(function(){
      var $this = $(this);

      if (window.wProfile && wProfile.profile.login) {
        me._handleProductCta($this.find('.product-cta'));
      }
      else {
        me._handleProductGuest($this);
      }
    });
  },

  initProductDetail: function(){
    $('.component.product-details').each(function(){
      $(this).on('click', '.right h4', function(){
        $(this).toggleClass('show').next().slideToggle();
      });
    });
  },

  initProduct: function(){
    var me = this;

    $('.component.product').each(function(){
      var $product = $(this);
      var component_id = $product.data('id');
      var $warn = $product.find('.warn-message');
      var MAX_QUANTITY_PER_ORDER = 30;

      var currentProductInstanceId = $product.find('.product-type-name input:checked').parent('label').attr('data-type-pi-id');
      var $currentInstanceQuantity = $product.find(`.product-quantity .controller[data-pi-id="${currentProductInstanceId}"]`);
      var $num       = $currentInstanceQuantity.find('input[type="number"]');
      var $minus_btn = $currentInstanceQuantity.find('.minus-btn');
      var $plus_btn  = $currentInstanceQuantity.find('.plus-btn');

      var product_data = {
        feature_index: 0,
        feature_num: parseInt($product.find('.img-list').data('feature-num')),
        quantity_min: parseInt($currentInstanceQuantity.data('quantity')),
        quantity_max: parseInt($currentInstanceQuantity.data('quantity-max')),
        quantity: parseInt($currentInstanceQuantity.data('quantity'))
      };

      if (product_data.quantity_max > MAX_QUANTITY_PER_ORDER) {
        product_data.quantity_max = MAX_QUANTITY_PER_ORDER;
      }

      // feature img control
      var $feature = $product.find('.product-feature');
      var changeFeature = function(){
        var $target = $feature.find('[data-feature-index=' + product_data.feature_index + ']');
        $product.find('img.feature').attr('src', $target.attr('src'));
      };

      var moveFeature = function(way){
        product_data.feature_index += (way == 'next') ? 1 : -1;
        if (product_data.feature_index < 0) {
          product_data.feature_index = product_data.feature_num - 1;
        }
        else if (product_data.feature_index >= product_data.feature_num) {
          product_data.feature_index = 0;
        }
        changeFeature();
      };

      $feature
        .on('click', '.img-list img', function(){
          product_data.feature_index = $(this).data('feature-index');
          changeFeature();
        })
        .on('click', '.feature-judge-left', function(){
          moveFeature('last');
        })
        .on('click', '.feature-judge-right', function(){
          moveFeature('next');
        })
      me._bindSwipeEvent($feature, moveFeature);


      // quantity control
      $product.find('.product-quantity .controller')
        .on('change', 'input[type="number"]', function(){
          product_data.quantity = $(this).val();
          $warn.addClass('hide');
          $minus_btn.removeClass('disabled');
          $plus_btn.removeClass('disabled');

          if (product_data.quantity <= 1) {
            product_data.quantity = 1;
            $minus_btn.addClass('disabled');
          }
          else if (product_data.quantity == product_data.quantity_max) {
            product_data.quantity = product_data.quantity_max;
            $plus_btn.addClass('disabled');
          }
          else if (product_data.quantity > product_data.quantity_max) {
            $warn.removeClass('hide');
            window.setTimeout( function(){
              $warn.addClass('hide');
            }, 3000);
            product_data.quantity = product_data.quantity_max;
            $plus_btn.addClass('disabled');
          }

          $num.val(product_data.quantity);
        })
        .on('click', '.minus-btn', function(){
          $warn.addClass('hide');
          if (product_data.quantity_min != 0)
            $plus_btn.removeClass('disabled');

          if ($minus_btn.hasClass('disabled')) {
            if (window.ga) ga('send', 'event', 'website-tracking-ec', 'quantity-decrease-disabled', 'block18')
            return;
          }

          product_data.quantity -= 1;

          if (product_data.quantity <= 1) {
            product_data.quantity = product_data.quantity_min;
            $minus_btn.addClass('disabled');
          }
          else {
            $minus_btn.removeClass('disabled');
          }

          $num.val(product_data.quantity);
          if (window.ga) ga('send', 'event', 'website-tracking-ec', 'quantity-decrease', 'block18')
        })
        .on('click', '.plus-btn', function(){
          $warn.addClass('hide');
          if (product_data.quantity_min != 0)
            $minus_btn.removeClass('disabled');

          if ($plus_btn.hasClass('disabled')) {
            $warn.removeClass('hide');
            window.setTimeout( function(){
              $warn.addClass('hide');
            }, 3000);
            if (window.ga) ga('send', 'event', 'website-tracking-ec', 'quantity-increase-disabled', 'block18');
            return;
          }

          product_data.quantity += 1;

          if (product_data.quantity >= product_data.quantity_max) {
            product_data.quantity = product_data.quantity_max;
            $plus_btn.addClass('disabled');
          }
          else {
            $plus_btn.removeClass('disabled');
          }

          $num.val(product_data.quantity);
          if (window.ga) ga('send', 'event', 'website-tracking-ec', 'quantity-increase', 'block18');
        });

      // change product instance
      $product.find('.product-type')
        .on('change', 'input[type="radio"]', function(){
          var instance_id = $(this).parents('.product-type-name').attr('data-type-pi-id');
          $currentInstanceQuantity = $product.find(`.product-quantity .controller[data-pi-id="${instance_id}"]`);
          $num       = $currentInstanceQuantity.find('input[type="number"]');
          $minus_btn = $currentInstanceQuantity.find('.minus-btn');
          $plus_btn  = $currentInstanceQuantity.find('.plus-btn');

          $minus_btn.removeClass('disabled');
          $plus_btn.removeClass('disabled');

          product_data.quantity_min = parseInt($currentInstanceQuantity.data('quantity'));
          product_data.quantity_max = parseInt($currentInstanceQuantity.data('quantity-max'));

          if (product_data.quantity_max > 0) {
            if (product_data.quantity > product_data.quantity_max) {
              product_data.quantity = product_data.quantity_max;
              $num.val(product_data.quantity);
              $plus_btn.addClass('disabled');
            } else {
              if (product_data.quantity > 0) {
                $num.val(product_data.quantity);
              } else {
                product_data.quantity = 1;
                $num.val('1');
              }
            }
          } else {
            product_data.quantity = 0;
            $minus_btn.addClass('disabled');
            $plus_btn.addClass('disabled');
            $num.val('0');
          }

          if ($num.val() === '1') {
            $minus_btn.addClass('disabled');
          }

          $product.find('.product-quantity .controller').addClass('hide');
          $product.find(`.product-quantity .controller[data-pi-id="${instance_id}"]`).removeClass('hide');
          $product.find('.cta-btn.add-cart, .cta-btn.buy-it').attr('data-pi-id', instance_id);
        })

      // favorite
      var product_id = $product.data('product-id');

      if (window.wProfile && wProfile.profile.login) {
        wFavorite.get({ id: product_id, type: "Product" }).done(function(res){
          if (res.user_favorites && res.user_favorites.includes(product_id)) {
            $product.find('.product-popularity').addClass('loved');
          }
        });

        // add-cart & buy-it now
        me._handleProductCta($product.find('.product-cta'));
        $product.on('click', '.product-popularity', function(){
          var $favBtn = $(this);
          if (!wFavorite) return;

          if ($product.find('.product-popularity').hasClass('loved')) {
            wFavorite.rm({ id: product_id, type: "Product" }).done(function(res){
              $favBtn.removeClass('loved').find('span').text(res.favorites_count);
            })
          }
          else {
            wFavorite.set({ id: product_id, type: "Product" }).done(function(res){
              $favBtn.addClass('loved').find('span').text(res.favorites_count);
            })
          }
        });
      }
      else {
        me._handleProductGuest($product);
      }
    });
  },

  initQAswitch: function(){
    $('.component.qa').each(function(){
      var component_id = $(this).data('id');

      var $sec = $(this);
      var $page = $sec.find('.page');
      var $nav = $sec.find('nav > a');

      $nav.on('click', function(){
        var index = $(this).attr('data-index');
        $nav.children('img').removeClass('active');
        $page.hide();

        $nav.eq(index).children('img').addClass('active');
        $page.eq(index).fadeIn();

        if (window.ga) ga('send', 'event', 'website-tracking', 'component-qa-' + component_id, 'switch_' + index);
      });

      $nav.eq(0).click();
    });
  },

  initArticleEmbed: function(){
    $('.component.article_embed').each(function(){
      var $panel = $(this);
      var compoName = $panel.attr('class').replace(' ', '-') + '-' + $panel.data('id');
      var $articleObj = $panel.find('article');

      // send pv to embedded articles
      (function() {
      if (location.protocol != 'https:') return;

      var article_id = $articleObj.attr('data-id');
      if (!article_id) return;

      $.ajax({
        method: 'GET',
        dataType: 'json',
        xhrFields: { withCredentials: true },
        url: womany.domain_url + '/api-cf/view/a/' + article_id
      });
      })();

      // member login blocker, code fork from article-beta.js _insertLoginPromote()
      (function() {
        var bot_access = (navigator.userAgent && navigator.userAgent.match(/bot/i));
        var member_only = ($articleObj.attr('data-member') == 'true') || false;
        if (bot_access || !member_only) return;

        function _insertLoginBlocker($article) {
          var hardLimit = true;
          var blockType = 'login-blocker';

          var $articleBody = $article.find('.article-body');

          var loc = (function _findPosForLoginPromp($articleBody) {
            var paragraphs = $articleBody.children('p');
            var restlines = paragraphs.has('.nextpage');

            if (restlines.length)
              paragraphs = restlines;

            var targetDom = paragraphs[Math.floor(paragraphs.length * 0.33)]; // find central <p> or <p> with restline

            return {
              'targetDom': targetDom,
              'atRestline': restlines.length > 0
            };
          })($articleBody);

          var modalTemplate = require('../../templates/articles/login-blocker');
          var $loginModal = $(modalTemplate({ 'restline': loc.atRestline, 'link': location.pathname, 'add_trailer_restline': true }));
          $loginModal.insertBefore(loc.targetDom);
          $loginModal.nextAll().remove();

          if (window.ga) ga('send', 'event', 'website-tracking', compoName, 'login-blocker-attached_collection', { nonInteraction: true });

          $loginModal = $loginModal.find('.login-promote');

          var inview = wWaypoint({
            element: $loginModal[0],
            entered: function() {
              if (window.ga) ga('send', 'event', 'website-tracking', compoName, 'login-blocker-show_collection', { nonInteraction: true });
              inview.destroy();
            }
          });

          $loginModal.on('click', 'a', function() {
            var btnClass = this.getAttribute('class');
            if (window.ga) ga('send', 'event', 'website-tracking', compoName, 'login-blocker-click-' + btnClass + '_collection');
          });
        }

        (function _waitForWprofile() {
          if (!window.wProfile) {
            window.setTimeout(_waitForWprofile, 2000);
            return;
          }
          wProfile.ready(function(user_profile) {
            if (user_profile.login) return;
            _insertLoginBlocker($articleObj);
          });
        })();
      })();
    });
  },

  initEditorRef: function(){
    var $editorExist = $('.component.editor');
    if(!$editorExist.length) return;

    var page_name = window.location.pathname.split('/').pop();

    // complete link ref in component editor of codeforgender pages
    if (window.location.hostname == 'codeforgender.com') {
      page_name = page_name.length ? 'cfg-' + page_name : 'cfg-home';
    }
    $editorExist.find('a').each(function(){
      var original_link = $(this).attr('href');

      if (!original_link || !original_link.includes('ref=co')) return;

      $(this).attr('href', original_link.replace('ref=co', 'ref=co-' + page_name));
    });
  },
  initSaleTickets: function(){
    var $tickets = $('[data-ticket-id]');
    if (!$tickets.length) return;

    var ticket_ids = $tickets.toArray().map(function(ele){
      return $(ele).attr('data-ticket-id');
    });
    var ticket_id_list = ticket_ids.map(function(id){
      return 'ids[]=' + id;
    }).join('&');

    // 檢查庫存
    $.get(womany.api + '/e_commerce/v1/product_instances/info?' + ticket_id_list).done(function(res){
      if (res.status !== "success") throw new Error(res.status);
      ticket_ids.forEach(function(id){
        var $ticket = $('[data-ticket-id=' + id + ']');
        if (res.data.productInstances[id].inventory == 0) {
          $ticket.addClass('close');
          $ticket.removeClass('open error default');
        } else {
          $ticket.addClass('open');
          $ticket.removeClass('close error default');
        }
      });
    }).fail(function(e){
      // console.error(e)
      $tickets.addClass('error');
      $tickets.removeClass('open close default');
    });

    // 立即購票按鈕
    $tickets.on('click', function(){
      var $this = $(this);
      if ($this.hasClass('close') || $this.hasClass('error')) return;

      if (window.wProfile) wProfile.ready(function(user_profile){
        if (!user_profile.login) {
          wDialog.confirm('還不是 womany 會員嗎？', '', '馬上登入購物去').done(function(res){
            if (!res) return false;
            window.location = womany.domain_url + '/users/auth/womany'; // 可以在 url 最後用 ?r= 指定回來的網址
          });
          return false;
        }
        var id = $this.attr('data-ticket-id');
        wUtil.linkFormSubmit(womany.domain_url + '/orders/express?instance_id=' + id + '&quantity=1');
        return false;
      });
    });
  },
  initImagesLightbox: function() {
    var $lightBoxComp = $('.images-lightbox');
    $lightBoxComp.each(function(index, item) {
      var $comp = $(item);
      var $controls = $comp.find('.control');
      var $blockList = $comp.find('.image-block-list');
      var $lightBox = $comp.find('.lightbox-outer');

      $comp.on('click', '.show-more', function(e){
          e.preventDefault();
          var lightboxIndex = parseInt($(e.target).attr('data-openlightbox'));
          $comp.find('.image-block-list').slick({
            initialSlide: lightboxIndex,
            slidesToScroll: 1,
            slidesToShow: 1,
            draggable: true,
            prevArrow: $controls[0],
            nextArrow: $controls[1],
            variableWidth: true,
            dots: true
          });
          $lightBox.removeClass('hide');
        })
        .on('click', '.fa-times', function(e){
          e.preventDefault();
          $blockList.slick('unslick');
          $lightBox.addClass('hide');
        })
        .on('click', '.lightbox-outer', function(e) {
          e.preventDefault();
          if ($(e.target).hasClass('lightbox-outer')) {
            $lightBox.addClass('hide');
            $blockList.slick('unslick');
          }
        })
    })
  },
  initSlickCarousel: function() {
    $('.component.slick_carousel').each(function(index, item) {
      const $controls = $(item).find('.control');
      const slickAutoPlay = ($(item).attr('data-slick-auto-play') == '1') ? true : false;
      $(item).find('.slick-block-list').slick({
        slidesToScroll: 1,
        draggable: true,
        prevArrow: $controls[0],
        nextArrow: $controls[1],
        infinite: true,
        variableWidth: true,
        autoplay: slickAutoPlay
      }).on('swipe', function(ev, slick, direction){
        if (window.ga) ga('send', 'event', 'website-tracking', wgName, 'swipe/drag-' + direction);
      });

      $controls.on('click', function(){
        if (window.ga) {
          if ($(this).hasClass('left')){
            ga('send', 'event', 'website-tracking', wgName, 'toggle-slide-left');
          } else {
            ga('send', 'event', 'website-tracking', wgName, 'toggle-slide-right');
          }
        }
        return false;
      });

    })
  },
  initDataIntro: function() {
    var me = this;
    $('.component.data-intro').each(function(){
      if ($(this).attr('data-theme') != 'count_up') return;
      var $numberDoms = $(this).find('.countup-data');
      $numberDoms.map(function(index, item){
        var $dom = $(item);
        var num = $dom.text().match(/^\d+(\.\d+)?/g)[0];
        var txt = $dom.text().replace(num, ' ');
        var inview = new wWaypoint({
          element: item,
          enter: function(direction) {
            // seperate numbers and content, start to countup
            $dom.html(`<span class="num">0</span><span>${txt}</span>`);
            var $num = $dom.find('.num');
            $num.attr('data-wcount-to', num);
            $num.attr('data-wcount-timelen', 1);
            $num.attr('data-wcount-decimal', 1);
            $num.wCountUp();
            this.destroy();
          }
        });

      })
    })
  },
  initTeamzine: function(){
    $('.component.teamzine').each(function(){
      var component_id = $(this).data('id');
      var $teamzine = $('#block' + component_id);

      $teamzine.find('.story-loadmore').on('click', function(){
        $(this).remove();
        $teamzine.find('.stories li').removeClass('hide');
      })
    });
  }
};

wComponent.init();

// export default wComponent;
window.wComponent = wComponent;
