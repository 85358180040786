import wShareTrack from '../shared/wShareTrack';

/*
 * Open FB share dialog window
 *
 *  wFb.share(data, doPortion);
 *
 *  var data = {
 *    name: Shared title, default og:title
 *    link: Shared Link, default og:url
 *    description: Description of link, default og:description
 *    picture: Image of link, default og:image
 *    recordFunc: function(response)
 *        a function which will be called after user done sharing.
 *        useful for recording/send shared events
 *        response.post_id: user's fb post id    // depreciated and is empty now (https://developers.facebook.com/docs/sharing/reference/feed-dialog#response)
 *        if use GA event tracking, remember to send page url (window.location.pathname)
 *        due to this function is called by closed FB window
 *    }
 *  }
 *
 *  var doPortion (true|false): default false
 *    false: only send og:url or current url to fb.ui, don't pass /s and thus doesn't affect like target
 *
 */

var wFb = {
  _renderPreviewBox: function(shareDat, para, callbackFunc){
    shareDat.share_url = para.link;
    var $previewBox = $(require('../../templates/wfb/preview-box')(shareDat));

    $previewBox
      .on('click', '#share-btn', function () {
        FB.ui(para, callbackFunc);
        $previewBox.remove();
        return false;
      })
      .on('click', function (e) {
        if (e.target && (e.target.id == 'cancel-btn' || e.target.id == 'wfb-share-preview')){
          $previewBox.remove();
          return false;
        }
      });

    $previewBox.appendTo('body');
  },

  _previewShare: function(shareDat, para, callbackFunc){
    var me = this;

    $.post(womany.api + '/portions', shareDat).done(function(res){
      if (res && res.status && res.status == 'success') para.link = "https://womany.net/s/" + res.code;
      if (window.ga) ga('send', 'event', 'website-tracking', 'portion', 'create_' + para.link);

      me._renderPreviewBox(shareDat, para, callbackFunc);
    });
  },

  share: function(dat, doPortion){
    var me = this;
    var doPortion = doPortion || false;

    // remove due to drop of publish_actions priviledge
    /*
    if (dat.recordFunc) {
      FB.login(function() {
        me._prepareShare(dat, doPortion);
      }, {scope: 'publish_actions'});
      return;
    }
    */

    me._prepareShare(dat, doPortion);
  },

  _prepareShare: function(dat, doPortion){
    var me = this;

    var para = {
      method: 'feed',
      display: 'popup',
      link: dat.link || $("meta[property='og:url']").attr('content')
    };
    var callbackFunc = dat.recordFunc || function (response) { /* response.post_id */ };

    para.link = para.link.replace(/^\/\//, 'http://');
    if (!para.link.includes('http')) para.link = 'http://' + para.link;
    para.link = wShareTrack.appendStmToUrl(para.link);

    if (!doPortion){
      FB.ui(para, callbackFunc);
      return;
    }

    var shareDat = {
      'url': para.link,
      'title': dat.name || $("meta[property='og:title']").attr('content') || "",
      'description': dat.description || $("meta[property='og:description']").attr('content') || "",
      'image': dat.picture || $("meta[property='og:image']").attr('content') || ""
    };

    if (shareDat.description.length > 253)
      shareDat.description = shareDat.description.substr(0, 253) + "⋯";

    shareDat.image = shareDat.image.replace(/^\/\/|^http:\/\//, 'https://');

    me._previewShare(shareDat, para, callbackFunc);
  }
};

export default wFb;
window.wFb = wFb;
