var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "<div id=\"wfb-share-preview\">\n  <div class=\"share-contain\">\n\n    <div id=\"preview-section\">\n      <span class=\"img\" style=\"background-image:url("
    + alias5(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\"></span>\n      <div class=\"text\">\n        <h1 class=\"portion-title\">"
    + alias5(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h1>\n        <p class=\"desc\">"
    + alias5(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"description","hash":{},"data":data}) : helper)))
    + "</p>\n        <p class=\"url\">"
    + alias5(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"url","hash":{},"data":data}) : helper)))
    + "</p>\n      </div>\n    </div>\n\n    <div class=\"action\">\n      <a id=\"share-btn\" class=\"wfb-btn\" href=\"https://www.facebook.com/sharer/sharer.php?u="
    + alias5(((helper = (helper = helpers.share_url || (depth0 != null ? depth0.share_url : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"share_url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">分享到 Facebook</a>\n      <a id=\"cancel-btn\" class=\"wfb-btn\" href=\"javascript:void(0)\";>取消</a>\n    </div>\n\n  </div>\n</div>\n";
},"useData":true});