var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<span class='nextpage'></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<p class=\"login-blocker-top\">\n\n<span class='login-promote' data-status='<!--ignored-->'>\n  <img src=\"https://womany.net/cdn-cgi/image/f=auto/https://castle.womany.net/images/content/pictures/126292/34945b5ef686e2a3597c78ffef9126bc.png\" width=\"202\">\n  <span>如果喜歡這篇文章，歡迎登入閱讀更多</span>\n  <a class='login-btn' href='/users/auth/womany?r="
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"link","hash":{},"data":data}) : helper)))
    + "' rel='nofollow'>登入看完整內容</a>\n</span>\n\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.add_trailer_restline : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</p>\n";
},"useData":true});