/*
 *
 *  on page load, append shareaholic/sharetools dom with stm code,
 *  and replace history to remove stm code from current url
 *
 *  DNT: when enable DNT, we don't add stm to URL
 *
 *  wShareTrack.renderShareaholic($object);
 *  wShareTrack.renderSharetools($object);
 *
 *    $object: default $(body)
 *
 *    will looking for following dom in $object and replace with shareaholic dom with stm codes
 *
 *      shareaholic:
 *      <div class="wshare-shareaholic" data-sid="read" data-url="{{url}}" data-title="{{title}}" data-id="{{id}}"></div>
 *
 *      sharetools (in house):
 *      <div class="wshare-sharetools" data-sid="read" data-id="{{article.id}}"></div>
 *      <div class="wshare-sharetools" data-dir="center" data-sid="read" data-id="{{article.id}}"></div>
 *
 *    data-dir: (optional) make it center align, default is ""
 *    data-sid: (read / component / trial) set related shareaholic id, and when 'read', get share count with article api
 *
 *  wShareTrack.appendStmToUrl(url, reduceTimePrecise)
 *
 *    return url string with stm codes
 *    reduceTimePrecise: (optional, default false) return same stm for same url every 3600s
 *
 */

var wShareTrack = {
  portionsXhrCache: {},
  user_profile: {},

  _shareaholicID: {
    read: 6944891,
    component: 15855596,
    trial: 14328951
  },

  _init: function(){
    var me = this;

    if (window.wProfile) {
      wProfile.ready(function(user_profile) {
        me.user_profile = user_profile;

        me._checkUrlRemoveSTMcode();
        me.renderShareaholic();
        me.renderSharetools();
      });
    }
    else {
      $.ajax({
        url: womany.api + '/user',
        dataType: 'json',
        xhrFields: { withCredentials: true }
      }).done(function(res) {
        me.user_profile = res.data.user;

        me._checkUrlRemoveSTMcode();
        me.renderShareaholic();
        me.renderSharetools();
      });
    }
  },

  _checkUrlRemoveSTMcode: function(){
    if (!location.search.includes('stm')) return;
    var newUrl = this._rmStmFromUrl(location.href, true);
    history.replaceState(null, document.title, newUrl);
  },

  _rmStmFromUrl: function(strUrl, trackGA){
    trackGA = trackGA || false;
    if (!strUrl.includes('stm')) return strUrl;

    var urlObj = document.createElement('a');
    urlObj.href = strUrl;

    var params = new URLSearchParams(urlObj.search);
    if (trackGA && window.ga) ga('send', 'event', 'website-tracking', 'shareTrack', 'found_' + params.get('stm'));
    params.delete('stm');

    return this._combineUrlAndParamToStr(urlObj, params);
  },

  _combineUrlAndParamToStr: function(urlObj, params){
    var newUrl = urlObj.protocol + "//" + urlObj.host + urlObj.pathname;
    if (params.toString().length) newUrl = newUrl + '?' + params.toString();
    return newUrl + urlObj.hash;
  },

  appendStmToUrl: function(strUrl, reduceTimePrecise) {
    var me = this;
    reduceTimePrecise = reduceTimePrecise || false;
    var urlObj = document.createElement('a');
    urlObj.href = strUrl;

    if (navigator.doNotTrack == '1' && !me.user_profile.womanyor)  // DNT
      return strUrl;

    var stmCode = me._getSTMcode(reduceTimePrecise);
    if (!stmCode) return strUrl;

    var params = new URLSearchParams(urlObj.search);
    params.set('stm', stmCode);

    return me._combineUrlAndParamToStr(urlObj, params);
  },

  _appendUtmToUrl: function(strUrl) {
    var me = this;
    var urlObj = document.createElement('a');
    urlObj.href = strUrl;

    try {
      var params = new URLSearchParams(urlObj.search);
      params.set('utm_campaign', 'wsharetools');
      params.set('utm_medium', 'social');
      // params.set('utm_source', '');

      return me._combineUrlAndParamToStr(urlObj, params);
    }
    catch (error) {
      console.error(error); // IE11 on URL.search
      return strUrl;
    }
  },

  renderShareaholic: function($target){
    var me = this;

    $target = $target || null;
    if (!($target instanceof jQuery && $target.length))
      $target = $('body');

    var $shCanvas = $target.find('.wshare-shareaholic');
    if (!$shCanvas.length) return;

    var $meta = $('meta[property]');

    $shCanvas.each(function(){
      var $this = $(this);

      var shType = $this.data('sid');
      var shTgtID = $this.data('id');

      var shareDat = {
        originalUrl: $this.data('url') || $meta.filter("[property='og:url']").attr('content'),
        title: $this.data('title') || $meta.filter("[property='og:title']").attr('content'),
        url: null
      };

      shareDat.url = me.appendStmToUrl(shareDat.originalUrl);

      shareDat.appID = me._shareaholicID[shType];
      var $shareaHolic = $(require('../../templates/wsharetrack/shareaholic-tmpl')(shareDat));
      $this.replaceWith($shareaHolic);

      // rend shareaholic style
      me._getShareCount(shType, shTgtID, shareDat.originalUrl, $shareaHolic, 'shareaholic');
    });
  },

  renderSharetools: function($target){
    var me = this;

    $target = $target || null;
    if (!($target instanceof jQuery && $target.length))
      $target = $('body');

    var $shCanvas = $target.find('.wshare-sharetools');
    if (!$shCanvas.length) return;

    var $meta = $('meta[property]');

    $shCanvas.each(function(){
      var $this = $(this);

      var shType = $this.data('sid');
      var shTgtID = $this.data('id');

      var shareDat = {
        id: shType + '/' + shTgtID,
        originalUrl: $this.data('url') || $meta.filter("[property='og:url']").attr('content'),
        title: $this.data('title') || $meta.filter("[property='og:title']").attr('content'),
        url: null,
        alignCenter: $this.data('dir') == 'center',
        articleID: shTgtID,
        image: $this.data('image') || $meta.filter("[property='og:image']").attr('content'),
        description: $this.data('description') || $("meta[name='description']").attr('content').slice(0, 254) + '⋯'
      };

      shareDat.url = me._appendUtmToUrl(me.appendStmToUrl(shareDat.originalUrl, true));

      var $shareTools = $(require('../../templates/wsharetrack/sharetools-tmpl')(me._batchEncodeURIComponent(shareDat, ['url', 'title'])));
      $this.replaceWith($shareTools);  // insert renderDom first before append uts & get portions

      $shareTools.on('click', '.sharebtn', function(){
        if (window.ga) ga('send', 'event', 'website-tracking', 'wSharetools', 'click_' + this.getAttribute('data-type'));
      });
      me._getShareCount(shType, shTgtID, shareDat.originalUrl, $shareTools, 'sharetools');

      var $btns = $shareTools.find('.sharebtn');

      // append uts to sharetools
      var urlsWithSource = {};
      $btns.each(function(){
        var $btn = $(this);
        var targetSite = $btn.attr('data-type');
        urlsWithSource[targetSite] = shareDat.url + '&utm_source=' + $btn.attr('data-type');
        $btn.attr('href', $btn.attr('href').replace(encodeURIComponent(shareDat.url), encodeURIComponent(urlsWithSource[targetSite])));
      });

      // me._replaceShareURLtoPortionLink($btns, shareDat, urlsWithSource)
    });
  },

  _replaceShareURLtoPortionLink: function($btns, shareDat, urlsWithSource){
    var xhrCache = this.portionsXhrCache[shareDat.id];

    if (!xhrCache){
      var portionsDat = [];
      for (var site in urlsWithSource){
        portionsDat.push({
          title: shareDat.title,
          url: urlsWithSource[site],
          description: shareDat.description || null,
          image: shareDat.image || null
        });
      }

      xhrCache = this.portionsXhrCache[shareDat.id] = $.ajax({
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({portions_array: portionsDat}),
        url: womany.api + '/portions/batch'
      });
    }

    xhrCache.then(function(res) {
      if (!res || res.status !== 'success') return;
      if (!res.data || !res.data.results) return;

      var urlPortionMap = {};
      res.data.results.forEach(function(data){
        if (data.status && data.status == 'success') urlPortionMap[data.url] = data.code;
      });

      $btns.each(function(){
        var $btn = $(this);
        var targetSite = $btn.attr('data-type');

        var urlToReplace = urlsWithSource[targetSite];
        if (!urlPortionMap[urlToReplace]) return;

        var portionsUrl = 'https://womany.net/s/' + urlPortionMap[urlToReplace];
        $btn.attr('href', $btn.attr('href').replace(encodeURIComponent(urlToReplace), encodeURIComponent(portionsUrl)));
      });
    });
  },

  _batchEncodeURIComponent: function(obj, aryIemsToEncode){
    if (!aryIemsToEncode || !Array.isArray(aryIemsToEncode)) return obj;
    var newObj = {};
    for (var item in obj) newObj[item] = obj[item];

    aryIemsToEncode.forEach(function(item){
      if (newObj[item]) newObj[item] = encodeURIComponent(newObj[item]);
    });
    return newObj;
  },

  _getShareCount: function(type, id, url, $shareTools, toolsType){
    var me = this;

    if (type == 'read') {
      $.get(womany.api + '/articles/sharing_count?ids=' + id).done(function (dat) {
        if (!dat || (dat.status != 'success')) return;

        var shareCount = dat.data.articles[0].sharing_count;
        if (!shareCount) return;

        me._rendShareCount($shareTools, shareCount, toolsType);
      });
      return;
    }

    // page other than articles, require user login to get og_object
    if (!window.fbLoaded) return;
    window.fbLoaded.done(function(){
      FB.api('/', {
        "id": url,
        "fields": "engagement"
      },
      function (res) {
        if (!res || !res.engagement) return;
        var count = res.engagement.reaction_count + res.engagement.comment_count + res.engagement.share_count + res.engagement.comment_plugin_count;
        me._rendShareCount($shareTools, count, toolsType);
      });
    });
  },

  _rendShareCount: function ($shareTools, shareCount, toolsType){
    toolsType = toolsType || 'shareaholic';

    shareCount = wUtil.numberToKString(shareCount);

    if (toolsType == 'sharetools'){
      $shareTools.find('.sharebtn[data-type=facebook]').append('<span class="count">' + shareCount + '</span>');
      return;
    }

    function _rendShareNum() {
      var $shFbBtn = $shareTools.find('li.shareaholic-share-button[data-service=facebook]');
      if (!$shFbBtn.length) {
        window.setTimeout(_rendShareNum, 500);
        return;
      }

      var $shareBtn = $("<span class='share-button-counter'><span class='num'>" + shareCount + "</span></span>");
      $shFbBtn.find('.share-button-counter').replaceWith($shareBtn);
      $shFbBtn.addClass('has-shares');
    }
    _rendShareNum();
  },

  _getSTMcode: function(reduceTimePrecise){
    var me = this;
    reduceTimePrecise = reduceTimePrecise || false;

    if (!me.user_profile) return null;
    if (Number.parseInt === undefined) Number.parseInt = window.parseInt;

    var dateNow = Date.now() / 1000;
    dateNow = (reduceTimePrecise)? Math.floor(dateNow / 3600) * 3600 : Math.floor(dateNow);
    var dateStr = Number.parseInt('1' + dateNow.toString().split('').reverse().join('')).toString(36);

    var userID = (me.user_profile.login)?
      (('1' + (Number.parseInt('u' + ('0000000' + me.user_profile.id).substr(-7), 36) + '').split('').reverse().join('')) * 1).toString(36) :
      (('1' + (Number.parseInt('s' + me.user_profile.sid7, 36) + '').split('').reverse().join('')) * 1).toString(36);

    var stm = dateStr + userID + 'w';

    var chk = 0;
    Array.from(stm).forEach(function (ele) { chk += parseInt(ele, 36); });
    chk = (chk % 26 + 10).toString(36);

    return stm + chk;
  }
};

wShareTrack._init();

export default wShareTrack;
